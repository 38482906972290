<template>
  <div>
    <addmesure :activation="activMesure" v-if="activMesure && !devis.clientMeasures" :donnes="devis" @info="retourMesure"/>
    <updatemesure :activation="activMesure" v-if="activMesure && devis.clientMeasures" :donnes="devis" @info="retourMesure"/>
    <assurance :activation="activeAssurance" :donnes="devis" v-if="activeAssurance" @oga="retourAssurance"/>

    <div class="flex">
      <div class="premier bloc bg-fb rounded-6">
        <div class="p-10">
          <div class="flex w-full">
            <div class="w-4/5" @click="activeDetail =! activeDetail">

              <div
                  v-if="charge && client.length === 0"
                  class="flex justify-center mt-16"
              >
                <easy-spinner
                    type="dots"
                    size="40"
                />
              </div>

              <div class="w-full flex items-center" v-if="devis !== null">
                <label-name :first="devis.client.name" :second="devis.client.surname" height="40px" width="40px"/>
                <div class="ml-4 text-c18 text-left w-4/5">
                  <div>{{ devis.client.name }} {{ devis.client.surname }} </div>
                  <div class="text-90 text-c16">
                    {{ devis.client.phone }} | {{ devis.client.city }}, {{ devis.client.address }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-6" v-if="valueClient !== null">
            <div class="flex items-center">
              <div class="text-c24 font-c6">
                Devis Nº {{devis.reference}}
              </div>
              <div class="ml-4 w-1/6">
                <bouton
                  label="En édition"
                  height="23px"
                  color="red"
                  background="#DDDDDD"
                  radius="5px"
                  size="12px"
                  v-if="devis.state === 'IN_PROGRESS'"
                />
              </div>
            </div>

            <div class="flex mt-6">
              <div class="w-1/2">
                <div class="inputo text-left flex items-center bg-white pl-4 text-c14">
                  <span>{{ startDate.toLocaleDateString() }}</span>
                </div>
                <div class="bg-fb text-90 text-c12 mouv rounded-5 w-1/3 ml-3">
                  Date du devis
                </div>
              </div>

              <div class="w-1/2 ml-6">
                <datepicker :value-t="expiration" :minDate="expiration" height="50px"/>
                <div class="bg-fb text-90 text-c12 mouv rounded-5 w-2/5 ml-3">
                  Date d’expiration
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="diviser" />

        <div class=" p-10"  v-if="valueClient !== null">
          <article-liste />

          <div class="mt-6">
            <medecin @medecin="retourMedecin" v-if="devis !== null && devis.articles !== undefined"/>
          </div>

          <div class="mt-4">
            <ordonnance-liste :donnes="devis" @note="retourNote" @doc="retourDoc" @ordonnance="retourOrdonnance"/>
          </div>
        </div>

        <div class="diviser" />

        <div class="p-10 pt-6 pb-6 text-c14 text-60 text-left" v-if="valueClient !== null">
          <span class="text-black font-c5">Site: </span>  <span class="ml-2">OHO Les Lunettes, <span v-if="agence">{{ devis.agency.label.substr(0, 1).toUpperCase() }}{{ devis.agency.label.substr(1, devis.agency.label.length) }}</span> </span>
          <span class="ml-6 text-black font-c5">Vendeur: </span>  <span class="ml-2" v-if="devis.seller !== null && devis.seller !== undefined">{{ devis.seller.fullname }}</span>
        </div>
      </div>

      <div class="second ml-4">
        <div class="w-full">
          <div class="flex">
            <div class="w-2/5 mr-4">
              <bouton
                label="Aperçu"
                size="16px"
                weight="600"
                :icon="icons.eye"
                background="#EAEAEF"
                :charge="charge"
                color="black"
              />
            </div>

            <div class="w-3/5">
              <bouton
                label="Enregistrer"
                weight="600"
                size="16px"
                :charge="charge"
                @oga="saveDevis"
              />
            </div>
          </div>

          <div class="bloc bg-white rounded-6 p-6 mt-6 text-left">
            <div class="flex text-c16">
              <div class="text-90 w-1/2">Articles</div>
              <div class="w-1/2 font-c7 ml-4">
                <span v-if="devis !== null && devis.articles !== undefined">
                  {{devis.articles.length}}
                </span>
                <span v-if="devis === null">00</span>
              </div>
            </div>

            <div class="flex text-c16 mt-4">
              <div class="text-90 w-1/2">Montant total</div>
              <div class="w-1/2 font-c7 ml-4">{{amount}} Fr</div>
            </div>
          </div>

          <div class="bloc bg-white rounded-6 p-6 mt-6 text-left" v-if="devis !== null && devis.articles !== undefined">
            <div class="text-c16 text-90">
              Identifier un commercial externe
            </div>

            <div class="mt-4" v-if="commerciaux.length > 0">
              <multiselect
                  :icon="icons.user"
                  :value-t="valueCommercial"
                  :writeOption="true"
                  width="85%"
                  :option="commerciaux"
                  background="white"
                  height="50px"
                  @info="retourCommercial"
              />
            </div>
          </div>

          <bouton
              label="Modifier les Mesures du client"
              size="16px"
              radius="4px"
              weight="400"
              color="#fff"
              height="59px"
              background="#FF8888"
              :icon="icons.edit"
              class="mt-6"
              @oga="activMesure = true"
          />

          <div v-if="devis.clientMeasures">
            <mesure :donnes="devis"/>
          </div>

          <div class="flex justify-center mt-6" v-if="charge">
            <easy-spinner
                type="dots"
                size="40"
            />
          </div>

          <bouton
              label="Ajouter un prise en charge"
              size="16px"
              radius="4px"
              weight="400"
              color="#fff"
              height="59px"
              :icon="icons.edit"
              class="mt-6"
              @oga="activeAssurance = true"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eye from '../../../assets/icons/eye.svg'
import bouton from '../../helper/add/button'
import add from '../../../assets/icons/add2.svg'
import user from '../../../assets/icons/user-circle.svg'
import update from '../../../assets/icons/update.svg'
import articleListe from './articleListe'
import ordonnanceListe from './ordonnanceListe'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import multiselect from '../../helper/form/multiselect'
import datepicker from '../../helper/form/datePicker'
import labelName from '../../helper/add/nameLabel'
import medecin from './medecinSession'
import edit from '../../../assets/icons/edit.svg'
import updatemesure from '../../popup/updateMesure'
import addmesure from '../../popup/priseDeMesure'
import mesure from '../detailsDevis/mesure'
import assurance from '../../popup/priseEnCharge'

export default {
  name: "Index",

  components: {
    bouton,
    labelName,
    articleListe,
    ordonnanceListe,
    multiselect,
    datepicker,
    medecin,
    mesure,
    addmesure,
    updatemesure,
    assurance
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
  },

  data () {
    return {
      icons: {
        eye,
        add,
        update,
        user,
        edit
      },
      client: [],
      valueClient: null,
      activeDetail: false,
      activeClient: false,
      charge: true,
      dataClient: [],
      expiration: null,
      startDate: null,
      devis: null,

      note: '',
      commerciaux: [],
      comData: [],
      ordonnance: null,
      doc: null,
      ordonnanceUrl: '',
      docUrl: '',
      valueCommercial: null,
      pieces: {},
      activMesure: false,
      activeAssurance: false
    }
  },

  computed: {
    amount: function () {
      let data = this.$store.getters.update.articles
      let amount = 0
      if (data !== null) {
        for (let o in data) {
          if (data[o].amount !== undefined) {
            amount = amount + (parseInt(data[o].amount) * parseInt(data[o].quantity))
          } else {
            amount = amount + (parseInt(data[o].prix) * parseInt(data[o].qte))
          }
        }
      }
      return amount.toLocaleString()
    }
  },

  created () {
    this.allCommerciaux()
    this.expiration = new Date(new Date().getTime() + (14 * 24 * 60 * 60 * 1000))
    this.devis = this.$store.getters.update
    console.log('deviss')
    console.log(this.devis)
    this.valueClient = this.devis
    this.startDate = new Date()
    if (this.devis !== null) {
      this.startDate = new Date(this.devis.created_at)
      this.expiration = new Date(this.devis.expireDate)
    }
    if (this.devis.commercial !== null) {
      this.valueCommercial = this.devis.commercial.fullname
    }

    const pieces = this.$store.getters.trackPieces
    if (pieces !== null) {
      if (pieces.ordonnance !== undefined) {
        this.ordonnance = pieces.ordonnance
      }
      if (pieces.doc !== undefined) {
        this.doc = pieces.doc
      }
    }
  },

  methods: {
    retourAssurance (answer) {
      if (answer) {
        this.$router.push({ path: '/detailsDevis', query: { x: this.devis.id } })
      }
      this.activeAssurance = false
    },

    retourOrdonnance (answer) {
      this.ordonnance = answer
      this.pieces.ordonnance = answer
      this.$store.dispatch('savePieces', this.pieces)
    },

    retourDoc (answer) {
      this.doc = answer
      this.pieces.doc = answer
      this.$store.dispatch('savePieces', this.pieces)
    },

    retourCommercial (answer) {
      for (let i in this.comData) {
        if (this.comData[i].fullname === answer) {
          this.saveCommerciaux(this.comData[i].id)
        }
      }
    },

    retourMedecin (answer) {
      this.saveCommerciaux(answer)
    },

    saveCommerciaux (id) {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.addDoctor, {
        id: id,
        estimate: this.devis.id
      })
          .then(response => {
            console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    allCommerciaux () {
      http.get(apiroutes.baseURL + apiroutes.getCommerciaux + '?type=COMMERCIAL')
          .then(response => {
            this.comData = response
            const tab = []
            for (let item in response) {
              tab.push(response[item].fullname)
            }
            this.commerciaux = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    async saveDevis() {
      this.charge = true

      let tab =[]
      for (let x in this.devis.articles) {

        let body = {
          estimate: this.devis.id,
          quantity: 1,
          product: "",
          amount: parseInt(this.devis.articles[x].prix),
          meta: {
            treatmentData: null,
            oeil: null
          }
        }

        if (this.devis.articles[x].qte !== undefined){
          if (this.devis.articles[x].product !== undefined) {
            body.product = this.devis.articles[x].product.id
          } else {
            body.product = this.devis.articles[x].id
          }

          if (this.devis.articles[x].qte !== undefined) {
            body.quantity = parseInt(this.devis.articles[x].qte)
          }

          if (this.devis.articles[x].details !== undefined) {
            body.meta.treatmentData = this.devis.articles[x].details
          }

          if (this.devis.articles[x].oeil !== undefined) {
            body.meta.oeil = this.devis.articles[x].oeil
          }

          tab.push(body)
        }
      }

        this.charge = true

        // ENREGISTREMENT DES FICHIERS
        if (this.doc !== null) {
          await this.saveFile(this.doc, 'DOC')
        }

        if (this.ordonnance !== null) {
          await this.saveFile(this.ordonnance, 'ORDONNANCE')
        }
        if (this.ordonnanceUrl !== '' || this.docUrl !== '') {
          this.addFileToEstimate()
        }

        // lorsqu'il y a de details de traitement ou note
         this.updateEstimation()

        if (this.devis.order !== null && this.devis.order !== undefined){
          this.updateState()
        }
        // enregistrement des articles sur le devis
      if (tab.length > 0){
        this.addAllArticle(tab)
      } else {
        this.$router.push({ path: '/detailsDevis', query: { x: this.devis.id } })
      }

    },

    //Ici j'enregistre les fichiers et je récupère des url
    async saveFile (file, type) {
      this.charge = true
      let data = new FormData()
      data.append('files', file)
      let config = {
        header : {
          'Content-Type' : 'multipart/form-data'
        }
      }

      await http.post(apiroutes.baseURL + apiroutes.upload, data, config)
          .then(response => {
            if (type === 'DOC') {
              this.docUrl = response[0].url
            } else {
              this.ordonnanceUrl = response[0].url
            }
          })
          .catch(error => {
            console.log(error)
          })
    },

    //Ici j'ajoute les articles à l'estimation
    addAllArticle (body) {
      http.post(apiroutes.baseURL + apiroutes.addArticle, {
        "articles": body
      })
          .then(response => {
            console.log('yaaa')
            console.log(response)
            this.charge = false
            this.$router.push({ path: '/detailsDevis', query: { x: response.id } })
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    //Ici j'ajoute les fichiers à l'estimation
    addFileToEstimate () {
      console.log(this.devis)
      let body = {
        id: this.devis.id,
        documentUrls: this.devis.documentUrls
      }
      if (!this.devis.documentUrls) {
        body.documentUrls = []
      }

      if (this.docUrl !== '') {
        body.documentUrls.push(this.docUrl)
      }

      if (this.ordonnanceUrl !== '') {
        body.ordinanceUrls = [this.ordonnanceUrl]
      }

      http.post(apiroutes.baseURL + apiroutes.mesure, body)
          .then(response => {
            console.log('success file')
            console.log(response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourNote (answer) {
      this.note = answer
    },

    updateEstimation () {
      const body = {
        id: this.devis.id,
        treatmentData: null,
        meta: {
          treatmentData: null,
          note: this.note,
          updateAt: new Date().toISOString()
        },
      }

      if (this.note === this.devis.meta.note) {
        body.meta.note = this.devis.meta.note
      }
      http.put(apiroutes.baseURL + apiroutes.updateEstimate, body)
          .then(response => {
            console.log('save config')
            console.log(response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    updateState () {
      const body = {
        id: this.devis.order,
        state: "COORDINATION"
      }

      http.put(apiroutes.baseURL + apiroutes.updateState, body)
          .then(response => {
            console.log('save state')
            console.log(response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourMesure (answer) {
      if (answer) {
        this.devis.clientMeasures = answer
        console.log('ol')
        console.log(this.devis)
      }
      this.activMesure = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}
.diviser{
  height: 2px;
  background-color: #DCDCE4;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
.mouv{
  margin-top: -57px;
  margin-bottom: 60px;
}
.premier {
  width: 70%;
}
.second {
  width: 30%;
}

.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  height: 50px;
  border-radius: 5px;
}
.inputo:focus-within {
  border: 1px solid $hover-color!important;
}
.inputo:hover {
  border: 1px solid $hover-color!important;
}
</style>
