<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Prise en charge
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">
            <div class="flex justify-center">
              <easy-spinner
                  v-if="charge"
                  type="dots"
                  size="40"
              />
            </div>

            <div v-if="allInfo.length > 0">
              <div class="text-left text-c12">
                Maison d’assurance/partenarie
              </div>

              <multiselect
                  :option="allInfo"
                  background="white"
                  class="mt-2"
                  width="36%"
                  size="14px"
                  value-t="Sélectionnez la compagnie concernée"
                  @info="retourCompagnie"
              />
            </div>

            <div class="mt-6">
              <div class="text-left text-c12">
                Référence du dossier
              </div>

              <div class="mt-2">
                <inputo
                    @info="retourRef"
                />
              </div>
            </div>

            <div class="mt-6" v-if="calculeVerre() > 0">
              <div class="text-left text-c12">
                Valeur des Verres
              </div>

              <div class="mt-2">
                <inputo
                    type="number"
                    @info="retourVerre"
                />
              </div>

              <div class="text-left text-c12 text-60 mt-2">
                Quote du client : <span class="text-red">
                <span v-if="response.glassAmount !== undefined && response.glassAmount !== ''">
                  {{ (calculeVerre() - response.glassAmount).toLocaleString() !== 'NaN' ? (calculeVerre() - response.glassAmount).toLocaleString() : 0}}
                </span>
                 Fr
              </span>
              </div>
            </div>

            <div class="mt-6" v-if="calculeMonture() > 0">
              <div class="text-left text-c12">
                Valeur de la monture
              </div>

              <div class="mt-2">
                <inputo
                    type="number"
                    @info="retourMonture"
                />
              </div>

              <div class="text-left text-c12 text-60 mt-2">
                Quote du client : <span class="text-red">
                 <span>
                  {{ (calculeMonture() - response.frameAmount).toLocaleString()}}
                </span>
                Fr
              </span>
              </div>
            </div>

            <div class="text-left text-c12 mt-6">
              Date d’expiration
            </div>

            <div class="mt-2">
              <datePicker
                  :min-date="new Date()"
                  @info="retourDate"
              />
            </div>

            <div class="mt-10">
              <upload
                  :icon="icons.document"
                  background="#FBFBFB"
                  border="1px dashed #C4C4C4"
                  radius="5px"
                  size="14px"
                  display="flex"
                  height="76px"
                  align="left"
                  label="Ajouter la référence de la prise en charge"
                  color="#606060"
                  @info="retourDoc"
              />
            </div>

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c13"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                radius="0px 0px 10px 10px"
                height="55px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import multiselect from '../helper/form/multiselect'
import datePicker from '../helper/form/datePicker'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import inputo from '../helper/form/input'
import upload from '../helper/form/uploadTemplate'
import document from '../../assets/icons/document.svg'

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
    datePicker,
    multiselect,
    inputo,
    upload
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer,
        document
      },
      error: null,
      charge: true,
      allInfo: [],
      compagnie: [],
      response: {
        glassAmount: 0,
        frameAmount: 0,
        meta: {}
      },
      file: null,
      percentage: 0
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.getCompany()
    this.response.estimate = this.donnes.id
    console.log('prise en charge')
    console.log(this.donnes)
    this.percentage = (this.donnes.financialData.discountAmount * 100) / this.donnes.financialData.totalAmount
    console.log(this.percentage)
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    calculeVerre () {
      let amount = 0
      const data = this.donnes.articles
      for (let item in data) {
        if (data[item].product.type === 'GLASS' || data[item].product.type === 'SERVICE' || data[item].product.type === 'TREATMENT') {
          amount = amount + data[item].amount
        }
      }
      return amount - ((amount*this.percentage)/100)
    },

    retourDoc (answer) {
      this.file = answer
    },

    calculeMonture () {
      let amount = 0
      const data = this.donnes.articles
      for (let item in data) {
        if (data[item].product.type === 'FRAMES') {
          amount = amount + data[item].amount
        }
      }
      return amount - ((amount*this.percentage)/100)
    },


    getCompany () {
      http.get(apiroutes.baseURL + apiroutes.allCompany)
          .then(response => {
            this.compagnie = response
            for (let item in response) {
              this.allInfo.push(response[item].name)
            }
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    async continuer() {
      this.error = null
      if (this.response.insurance && this.response.expireDate) {
        if (this.calculeVerre() >= this.response.glassAmount && this.calculeMonture() >= this.response.frameAmount) {
          this.charge = true
          if (this.file !== null) {
            await this.saveFile()
          }
          this.save()
        }
        else {
          this.error = 'Désolé le montant de la prise en charge est supérieur au montant requis'
        }
      } else {
        this.charge = false
        this.error = 'Veuillez remplir les champs pour continuer'
      }
    },

    //Ici j'enregistre les fichiers et je récupère l'url
    async saveFile () {
      this.charge = true
      let data = new FormData()
      data.append('files', this.file)
      let config = {
        header : {
          'Content-Type' : 'multipart/form-data'
        }
      }

      await http.post(apiroutes.baseURL + apiroutes.upload, data, config)
          .then(response => {
              this.response.meta.referenceUrl = response[0].url
          })
          .catch(error => {
            console.log(error)
          })
    },

    save () {
      http.post(apiroutes.baseURL + apiroutes.addInssurance,
          {
            "supports": [this.response]
          })
          .then(response => {
            this.$emit('oga', true)
            console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    retourDate (answer) {
      this.response.expireDate = answer
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

    retourMonture (answer) {
      if (answer !== '' && answer !== null) {
        this.response.frameAmount = parseInt(answer)
      } else {
        this.response.frameAmount = 0
      }
    },

    retourCompagnie (answer) {
      this.position = answer
      for (let item in this.compagnie) {
        if (this.compagnie[item].name === answer) {
          this.response.insurance = this.compagnie[item].id
        }
      }
    },

    retourVerre (answer) {
      if (answer !== '' && answer !== null) {
        this.response.glassAmount = parseInt(answer)
      } else {
        this.response.glassAmount = 0
      }
    },

    retourRef (answer) {
      this.response.reference = answer
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
