<template>
  <div class="p-10 pr-16">
    <div>
      <div class="">
        <div class="w-2/3 flex text-left text-c34 items-center font-c7">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retourBack"
          />
          <div class="ml-4">
            Modifier devis
          </div>
        </div>
      </div>

      <div class="mt-6 flex ml-4">
<!--        <div class="w-1/4">-->
<!--          <div class="bloc bg-white rounded-6 p-5">-->
<!--            <div class="alert p-4 pb-10 text-left text-c14">-->
<!--              Veuillez remplir les différents champs pour rédiger votre proposition de devis à soumettre au client-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="w-full">
          <new-devis/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import add from '../../assets/icons/add.svg'
import newDevis from '../../component/cdevs/updateDevis/newDevis'

export default {
  name: "Index",

  components: {
    newDevis
  },

  data () {
    return {
      icons: {
        back,
        add
      },
      activeCoupon: false,
      codeCoupon: null,
      devis: null
    }
  },

  created () {
    this.devis = this.$store.getters.update
    console.log('vision clear', this.devis)

    if (this.devis.meta) {
      if (this.devis.meta.couponUse) {
        console.log('coup', this.devis.meta.couponUse)
        this.$store.dispatch('saveCoupon', this.devis.meta.couponUse)
      }
    }

    if (this.devis.coupon) {
      this.$store.dispatch('saveCoupon', this.devis.coupon)
    }
  },

  methods: {
    retourBack () {
      window.history.back()
    },

    retourCoupon (answer) {
      if (answer !== false) {
        this.codeCoupon = answer
      }
      this.activeCoupon = false
    }

  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
.alert {
  background: #FFF0F0;
  border-radius: 5.10135px;
}
</style>
