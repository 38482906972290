<template>
  <div class="text-left">
    <div class="text-c12">Ajouter un médecin</div>

    <div class="mt-4">
     <multiselect
         v-if="medecin.length > 0"
         :icon="icons.user"
         :value-t="valueDoctor"
         :writeOption="true"
         width="90%"
         :option="medecin"
         background="white"
         height="50px"
         @info="retourMedecin"
     />
    </div>

    <div
        class="mt-4 flex w-4/5 mr-auto ml-auto"
    >
      <div class="w-full">
        <bouton
            label="Ajouter un article"
            border="0.5px dashed #5138EE"
            size="14px"
            color="#5138EE"
            background="#EFF4FF"
            @oga="retourAdd"
        />
      </div>
    </div>

    <div class="flex justify-end mt-20">
      <div class="text-right w-1/2">
        <div class="flex items-center">
          <div class="w-1/2 text-c14">Valeur Totale : </div>
          <div class="w-1/2 ml-4 bord text-c18 font-c5">{{amount}} Fr </div>
        </div>

        <div class="flex mt-4 items-center">
          <div class="w-1/2 text-c14">Prise en charge :</div>
          <div class="w-1/2 ml-4 bord text-c18 font-c5">- {{devis.financialData.supportAmount}} Fr </div>
        </div>

        <div class="flex mt-4 items-center">
          <div class="w-1/2 text-c14">Remise : </div>
          <div class="w-1/2 ml-4 bg-f5 rounded-5 p-1 pl-2 pr-2 text-c18 font-c5">0 Fr </div>
        </div>

        <div class="flex mt-4 items-center">
          <div class="w-1/2 text-c14">Frais de livraison :  </div>
          <div class="w-1/2 ml-4 bg-f5 rounded-5 p-1 pl-2 pr-2 text-c18 font-c5">{{devis.financialData.deliveryAmount}} Fr </div>
        </div>

        <div class="w-full fin mt-6 rounded-5 flex items-center font-c7">
          <div class="w-1/2 text-c14">Net à payer :  </div>
          <div class="w-1/2 ml-4 rounded-5 p-1 pl-2 pr-2 text-c18">{{amount}} Fr</div>
        </div>
      </div>
    </div>

    <diviser
      height="1.8px"
      color="#5138EE"
      class="mt-10"
    />
  </div>
</template>

<script>
import bouton from '../../helper/add/button'
import diviser from '../../helper/add/divider'
import user from '../../../assets/icons/user-circle.svg'
import multiselect from '../../helper/form/multiselect'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",

  components: {
    bouton,
    diviser,
    multiselect
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        user
      },
      devis: null,
      medecin: [],
      doctor: [],
      valueDoctor: null
    }
  },

  computed: {
    amount: function () {
      let data = this.$store.getters.update.articles
      let amount = 0
      if (data !== null) {
        for (let o in data) {
          if (data[o].amount !== undefined) {
            amount = amount + (parseInt(data[o].amount) * parseInt(data[o].quantity))
          } else {
            amount = amount + (parseInt(data[o].prix) * parseInt(data[o].qte))
          }
        }
      }
      return amount.toLocaleString()
    }
  },

  created () {
    this.devis = this.$store.getters.update
    this.allMedecin()
    if (this.devis.doctor !== null) {
      this.valueDoctor = this.devis.doctor.fullname
    }
  },

  methods: {
    retourAdd () {
      this.$store.dispatch('saveBasket', this.devis.articles)
      this.$router.push('/panier')
    },

    retourMedecin (answer) {
      for (let i in this.doctor) {
        if (this.doctor[i].fullname === answer) {
          this.$emit('medecin', this.doctor[i].id)
        }
      }
    },

    allMedecin () {
      http.get(apiroutes.baseURL + apiroutes.getCommerciaux + '?type=DOCTOR')
          .then(response => {
            this.doctor = response
            const tab = []
            for (let item in response) {
              tab.push(response[item].fullname)
            }
            this.medecin = tab
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}
.diviser{
  height: 1px;
  background-color: #DCDCE4;
}
.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  height: 50px;
  border-radius: 5px;
}
.inputo:focus-within {
  border: 1px solid $hover-color!important;
}
.inputo:hover {
  border: 1px solid $hover-color!important;
}
.bord {
  border-bottom: 0.5px solid #DDDDDD;
}
.fin {
  background-color: #EFF4FF;
}

</style>
